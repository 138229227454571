import { render, staticRenderFns } from "./TourRequestForm.vue?vue&type=template&id=b22b8dfc&scoped=true&"
import script from "./TourRequestForm.vue?vue&type=script&lang=js&"
export * from "./TourRequestForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b22b8dfc",
  null
  
)

export default component.exports