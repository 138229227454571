<template>
  <div >
       <section class="contact-us-page">
                <div class="container">
                    <div class="contact-us-page-wrapper">
                        <div class="contact-us-page-top-content">
                            <h2>Contact Us</h2>
                            <p>We are available 24/7 to take your enquiries and complaints.</p>
                        </div>
                        <div class="contact-us-page-form-wrapper">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="contact-us-page-ltr">
                                        <form>
                                            <div class="form-group row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"><input type="text"
                                                        class="form-control" placeholder="Name"></div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"><input type="text"
                                                        class="form-control" placeholder="Email"></div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"><textarea
                                                        class="form-control" name="message"
                                                        placeholder="Message"></textarea></div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"><button
                                                        class="contact-us-page-btn app-btn">Send a Message</button></div>
                                            </div>
                                        </form>
                                        <div class="contact-us-page-social">
                                            <h2>Or Reach us on our socials</h2>
                                            <ul class="social-link">
                                                <li><a class="facebook" target="_blank" href="">
                                                        <img src="@/assets/img/icons/youtube.svg" alt="" />
                                                    </a>
                                                </li>
                                                <li><a class="instagram" target="_blank" href="/">
                                                        <img src="@/assets/img/icons/instagram.svg" alt="" />
                                                    </a>
                                                </li>
                                                <li><a class="twitter" target="_blank" href="/">
                                                        <img src="@/assets/img/icons/twitter.svg" alt="" />
                                                    </a>
                                                </li>
                                                <li><a class="youtube" target="_blank" href="/"> <img
                                                            src="@/assets/img/icons/youtube.svg" alt="" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="contact-us-page-rtl">
                                        <figure><img class="img-fluid" src="@/assets/img/ContactUsPageImg.png"
                                                alt="Contact US">
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
  </div>
</template>
<script>
// import SocialIcons from '../components/general/SocialIcons.vue'

export default {
  name:"ContactUs",
  methods:{
 
   sendMessage(){}
  }
 
}
</script>

<style  scoped>
  .app-btn{
      background: linear-gradient(
249deg
, #6FA73D, #00b1ab);
  }
</style>