<template>
    <div>
         <section class='auth-page'>
                <div class='container'>
                    <div class='row'>
                        <div class='offset-lg-3 col-lg-6'>
                            <form>
                                <div class='auth-wrapper'>
                                    <div class='auth-wrapper-top text-center'>
                                        <h2>Register</h2>
                                        <p>
                                            <span class='divider'>Welcome Back, Lets get back to it</span>
                                        </p>
                                    </div>
                                    <div class='form-wrapper'>
                                        <div class='form-group row'>
                                            <div class='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                                <div class='auth-input-icon user'>
                                                    <img src='@/assets/img/icons/profile.png' alt='Full Name' />
                                                </div>
                                                <input type='text' name='title' class='form-control '
                                                    placeholder='Full Name' />
                                            </div>
                                        </div>

                                        <div class='form-group row'>
                                            <div class='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                                <div class='auth-input-icon email'>
                                                    <img src='@/assets/img/icons/email-icon.png' alt='Email Address' />
                                                </div>
                                                <input type='text' name='title' class='form-control '
                                                    placeholder='Email Address' />
                                            </div>
                                        </div>

                                        <div class='form-group row'>
                                            <div class='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                                <div class='auth-input-icon password'>
                                                    <img src="@/assets/img/icons/lock-icon.png" alt='Password' />
                                                </div>
                                                <input type='password' name='title' class='form-control '
                                                    placeholder='Password' />
                                                <div class='auth-password-show-hide-icon'>
                                                    <i class="fe fe-eye"></i>
                                                    <!-- {hidden ? (
                                                    <Icons.Eye onClick={toggleShow} size='20px' />
                                                    ) : (
                                                    <Icons.EyeOff onClick={toggleShow} size='20px' />
                                                    )} -->
                                                </div>
                                            </div>
                                        </div>

                                        <div class='form-group row'>
                                            <div class='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                                <p>
                                                    By signing up to Housing Solution Fund you agree to our
                                                    <a href='' class='site-link-btn'>
                                                        Private Policy
                                                    </a>
                                                    and
                                                    <a href='' class='site-link-btn'>
                                                        Terms of Service
                                                    </a>
                                                </p>

                                                <button type='button' class='auth-btn'>
                                                    Sign up
                                                </button>
                                                <p>
                                                    Already have an Account?
                                                    <router-link :to="{name:'Login'}" class='site-link-btn'>
                                                        Login
                                                    </router-link>
                                                   
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
    </div>
</template>
<script>
export default {
    name:"Register"
}
</script>