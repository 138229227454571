<template>
    <div>
         <div class="modal fade" id="detailModal" tabindex="-1" role="dialog"
                aria-labelledby="confirmationModalTitle" style="display: none;" aria-modal="true">
                <div class="photo-modal modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header"><button type="button" class="close" data-dismiss="modal"
                                aria-label="Close"><i class="fa fa-times" aria-hidden="true"></i></button></div>
                        <div class="modal-body">
                            <div class="photo-modal-wrapper">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <div class="property-info-section">
                                            <div class="row">
                                                <div class="col-lg-8">
                                                    <div class="location screen414">
                                                        <h2>Listing Photos ({{property.property_photos.length}})</h2>
                                                    </div>
                                                    <div class="location screen800">
                                                        <h2>{{property.property_name}}</h2>
                                                        <p>
                                                            <img src="@/assets/img/icons/location.png"
                                                                alt="Lekki penisula II, Lekki Lagos State, Nigeria">
                                                             {{property.city.name}} {{property.state.name}}, Nigeria
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="property-price">
                                                        <span>From</span>
                                                        <h2>{{property.property_price | price}}.<small>00</small></h2>
                                                        <a href="" class="get-pre-qualified-cta-btn">Get
                                                            prequalified</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="photo-modal-section">
                                            <div id="carouselPropertyIndicator" class="carousel slide"
                                                data-ride="carousel">
                                                <div class="carousel-inner" role="listbox">
                                                    <div class="carousel-item " :class="{ active: i==0 }"
                                                        v-for="(p,i) in property.property_photos" :key="p"
                                                         :style="{backgroundImage:'url('+p+')'}">
                                                    </div>


                                                </div>
                                                <a class="carousel-control-prev" href="#carouselPropertyIndicator"
                                                    role="button" data-slide="prev">
                                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span class="sr-only">Previous</span>
                                                </a>
                                                <a class="carousel-control-next" href="#carouselPropertyIndicator"
                                                    role="button" data-slide="next">
                                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span class="sr-only">Next</span>
                                                </a>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <a href="" @click.prevent="selectProperty" class="screen414 get-pre-qualified-cta-btn">Get
                                                prequalified</a>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <tour-request-form :property="property"/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
import EventBus from '@/services/event'
import TourRequestForm from './TourRequestForm.vue'
import {AppMixin} from '@/mixins/AppMixin';
export default {
    name:"PropertyDetailModal",
    mixins:[AppMixin],
    components:{
         TourRequestForm
    },
    props:
       {
        property:{type:Object}
    },
    methods:{
        openModal(){
            $('#detailModal').modal('show');
        },
        closeModal(){
             $('#detailModal').modal('hide');
        }
    },
    created(){
        EventBus.$on('detail-modal',(t)=>{
            if(t){
                this.openModal();
            }else{
                this.closeModal();
            }
        })
    }
}
</script>
<style lang="css" scoped>
         .photo-modal.modal-dialog {
                    max-width: 80vw !important;
                }

                .photo-modal-wrapper {
                    padding: 0 0.5rem;
                }

                .photo-modal-wrapper .property-price h2 {
                    font-size: 25px;
                }

                .photo-modal-section {
                    padding: 2rem 0;
                }

                .carousel-item {
                    height: 500px;
                    min-height: 350px;
                    background: no-repeat center center scroll;
                    background-size: cover;
                    background-color: #EFEFEF;
                    border-radius: 12px;
                }
</style>