<template>
    <div>
        <div class="request-schedule-form">
            <div id="tabs" class="request-schedule-form-tab">
                <nav>
                    <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                        <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab"
                            href="#nav-home" role="tab" aria-controls="nav-home"
                            aria-selected="true">Schedule
                            Tour</a>
                        <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab"
                            href="#nav-profile" role="tab" aria-controls="nav-profile"
                            aria-selected="false">Request Info</a>
                    </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel"
                        aria-labelledby="nav-home-tab">
                        <form class='request-schedule-form-wrapper' >
                            <!-- <input type="text" hidden v-model="selected_id"> -->
                            <div class="form-group row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <img class="icon icon-group"
                                        src="@/assets/img/icons/calendar.png" alt="Calender" />
                                    <input type="date" class="form-control" v-model="book_form.book_date"
                                    :class="{ 'is-invalid': book_tour_submitted && $v.book_form.book_date.$error }"
                                        placeholder="Prefered Date" />
                                </div>
                                    <div v-if="book_tour_submitted && !$v.book_form.book_date.required" class="form-error">Date is required</div> 
                            </div>

                            <div class="form-group row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <img class="icon icon-group" src="@/assets/img/icons/time.png"
                                        alt="Prefered Time" />
                                    <input type="time" class="form-control" v-model="book_form.book_time"
                                        placeholder="Prefered Time"   :class="{ 'is-invalid': book_tour_submitted && $v.book_form.book_time.$error }" />
                                </div>
                                        <div v-if="book_tour_submitted && !$v.book_form.book_time.required" class="form-error">Time is required</div> 
                            </div>

                            <div class="form-group row">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <img class="icon icon-group" src="@/assets/img/icons/time.png"
                                        alt="Full Name" />
                                    <input type="text" class="form-control"
                                        placeholder="Full Name" v-model="book_form.name" 
                                            :class="{ 'is-invalid': book_tour_submitted && $v.book_form.name.$error }" />
                                                <div v-if="book_tour_submitted && !$v.book_form.name.required" class="form-error">Name is required</div> 
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <img class="icon icon-group"
                                        src="@/assets/img/icons/phone.png" alt="Phone Number" />
                                    <input type="text" class="form-control" v-model="book_form.phone"
                                        placeholder="Phone"   :class="{ 'is-invalid': book_tour_submitted && $v.book_form.phone.$error }" />

                                            <div v-if="book_tour_submitted && !$v.book_form.phone.required" class="form-error">Phone is required</div> 
                                        
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <img class="icon icon-group"
                                        src="@/assets/img/icons/email.png" alt="Email address" />
                                    <input type="email" class="form-control"
                                        placeholder="Email Address" v-model="book_form.email"  :class="{ 'is-invalid': book_tour_submitted && $v.book_form.email.$error }" />
                                            
                                </div>
                                <div v-if="book_tour_submitted && !$v.book_form.email.required" class="form-error">Email is required</div> 
                            </div>

                            <div class="form-group row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-checkbox row">
                                        <div class="col-lg-6">
                                            <label>
                                                <input type="radio" name="schedule-medium" v-model="book_form.medium"
                                                    value="In Person" :class="{ 'is-invalid': book_tour_submitted && $v.book_form.medium.$error }" >
                                                <span> In Person</span>
                                            </label>
                                        </div>
                                        <div class="col-lg-6">
                                            <label>
                                                <input type="radio" name="schedule-medium" v-model="book_form.medium"  :class="{ 'is-invalid': book_tour_submitted && $v.book_form.medium.$error }"
                                                    value="Video Chat" checked="">
                                                <span> Video Chat</span>
                                            </label>
                                        </div>
                                    </div>
                                        <div v-if="book_tour_submitted && !$v.book_form.medium.required" class="form-error">Medium is required</div> 
                                </div>
                            </div>
                            

                            <div class="form-group row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <button type='submit' class='schedule-cta-btn' @click.prevent="submitBookTour()"
                                    >
                                    <span v-if="!savingTour">Schedule a
                                        Tour</span>
                                    <span v-if="savingTour">loading...</span>
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="tab-pane fade" id="nav-profile" role="tabpanel"
                        aria-labelledby="nav-profile-tab">
                        <form class='request-schedule-form-wrapper'>

                            <div class="form-group row">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <img class="icon icon-group" src="@/assets/img/icons/time.png"
                                        alt="Full Name" />
                                    <input type="text" class="form-control" v-model="enquiry_form.name"
                                        placeholder="Full Name" :class="{ 'is-invalid': enquiry_submitted && $v.enquiry_form.name.$error }" />
                                            <div v-if="enquiry_submitted && !$v.enquiry_form.name.required" class="form-error">Name is required</div> 
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <img class="icon icon-group"
                                        src="@/assets/img/icons/phone.png" alt="Phone Number" />
                                    <input type="text" class="form-control" v-model="enquiry_form.phone"
                                        placeholder="Phone" 
                                        :class="{ 'is-invalid': enquiry_submitted && $v.enquiry_form.phone.$error }"/>
                                            <div v-if="enquiry_submitted && !$v.enquiry_form.phone.required" class="form-error">Phone is required</div> 
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <img class="icon icon-group"
                                        src="@/assets/img/icons/email.png" alt="Email address" />
                                    <input type="email" class="form-control" v-model="enquiry_form.email"
                                        placeholder="Email Address" 
                                        :class="{ 'is-invalid': enquiry_submitted && $v.enquiry_form.email.$error }" />
                                            <div v-if="enquiry_submitted && !$v.enquiry_form.email.required" class="form-error">Email is required</div> 
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <textarea class="form-control" v-model="enquiry_form.message"
                                        placeholder="Message" :class="{ 'is-invalid': enquiry_submitted && $v.enquiry_form.message.$error }"></textarea>
                                            <div v-if="enquiry_submitted && !$v.enquiry_form.message.required" class="form-error">Message is required</div> 
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <button type='submit' class='schedule-cta-btn' @click.prevent="submitEnquiry()">  <span v-if="!savingEnquiry">Submit</span>
                                    <span v-if="savingEnquiry">loading...</span></button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, sameAs,requiredIf,numeric } from "vuelidate/lib/validators";
export default {
    name:"TourRequestForm",
    props:{
        property:{type:Object},
    },
    data(){
        return{
            book_tour_submitted:false,
            enquiry_submitted:false,
            savingTour:false,
            savingEnquiry:false,
          book_form:{
            property_id:'',
            developer_id:'',
            name:"",
            email:"",
            phone:"",
            book_date:"",
            book_time:"",
            medium:''
        },
        enquiry_form :{
            property_id:'',
            name:"",
            email:"",
            phone:"",
            message:''
        }
        }
    },
    validations:{
        book_form:{
            name:{required},
            email:{required,email},
            phone:{required,numeric},
            book_date:{required},
            book_time:{required},
            medium:{required}
        },
        enquiry_form:{
            name:{required},
            email:{required,email},
            phone:{required,numeric},
            message:{required}
        }
    },
    methods: {
         submitBookTour(){
            console.log("am sub")
             this.book_tour_submitted=true
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.savingTour = true;
            this.$store.dispatch("saveTourScheduleAction",this.book_form).then((res)=>{
                toast.success("Schedule sent successfully");
                this.book_tour_submitted =false;
                 this.savingTour = false;
                this.book_form ={
                property_id:'',
                name:"",
                email:"",
                phone:"",
                book_date:"",
                book_time:"",
                medium:''
                }
            }).catch((err)=>{
                 this.savingTour = false;
            })
            
        },
        submitEnquiry(){
            console.log("am en")
             this.enquiry_submitted=true
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.savingEnquiry = true;
            this.$store.dispatch("savePropertyEnquiryAction",this.enquiry_form).then((res)=>{
                toast.success("Schedule sent successfully");
                this.enquiry_submitted =false;
                 this.savingEnquiry = false;
                this.enquiry_form ={
                property_id:'',
                name:"",
                email:"",
                phone:"",
                book_date:"",
                book_time:"",
                medium:''
                }
            }).catch((err)=>{
                 this.savingEnquiry = false;
            })
            
        }
    },
        

        created(){
            this.book_form.property_id = this.property.id
        this.enquiry_form.property_id = this.property.id
        }
    
}
</script>
<style scoped>

</style>