<template>
<div >
  <search-properties/>
  <section class="property-affordable-list">
      <div class="container-fluid">
          <div class="property-affordable-list_wrapper row">
              <div class="col-lg-12">
                  <div class="property-result-list-top-content text-left">
                      <h2>Neighborhood, City, State</h2>
                      <p>{{properties.length}} Homes Available on Housing Solution Fund.</p>
                  </div>
              </div>
              <div class="col-lg-12">
                  <div class="property-cards-wrapper">
                    <!-- <div class="propert-flex">
                       <new-property-card class="child" :isChoose="false" :property="i" v-for="i in properties" :key="i.id"/>
                    </div> -->
                      <div class="row" v-if="!apiloading" >
                          <div class="col-lg-3" v-for="i in properties" :key="i.id">
                               <div class="property-list pr-0 v2">
                               <new-property-card :isChoose="false" :property="i" />
                               </div>
                              
                            </div>
                           
                      </div>
                      <div class="row" v-if="apiloading" >
                          <div class="col-lg-4" v-for="i in 10" :key="i">
                              <property-card-loader/>
                              
                            </div>
                      </div>
                         <pagination  :pagination="pagination" title="Properties" mutator="ALL_PROPERTIES" />

                        <div class="row" >
                          <div class="col-lg-12">
                              <div class="affordability-cta-btn-wrapper">
                                  <a @click.prevent="showAffordabilityModal" class="affordability-cta-btn pointer">
                                      Take affordability Test
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  
  </section>
   
</div>
 

</template>
<script>
import {AppMixin} from '@/mixins/AppMixin';
import {PropertiesMixin} from '@/mixins/PropertiesMixin';
import EventBus from '@/services/event'
import SearchProperties from '../components/SearchProperties.vue';
export default {
  name:"Properties",
  mixins:[AppMixin,PropertiesMixin],
  components:{
    SearchProperties,
  },
  data(){
    return{

      property_length:8,
       pageOfItems: [],
       list_type:this.$route.params.type,
    }
  },
  computed:{
      properties(){
        let all = this.$store.state.properties_module.properties;
        console.log("all_for",all)
        let property= all.data;
        this.fillPagination(all);
        return property;
      }
  },
  methods:{
    onChangePage(pageOfItems) {
            // update page of items
            this.pageOfItems = pageOfItems;
    },
    fetchProperties(){
      let t = this.$store.state.properties_module.list_style
        if(this.list_type != 'all'){
        }else{
           this.$store.dispatch("allPropertiesAction")
        }
         
    }
  },
  created(){
    console.log(this.list_type)
    this.fetchProperties();
  }
}
</script>
<style  scoped>
 
</style>