<template>
    <div >
        <section class="property-photo" v-if="!apiloading">
            <div class="container">
                <div class='property-photo-wrapper'>
                    <div class="row no-gutters">
                         <div class="col-lg-12">
                                <div class="return-nav-mobile">
                                    <a href="#" @click="goback()">
                                        <img src="assets/img/icons/back-icons.svg" alt="" />
                                        Back to Search
                                    </a>
                                </div>
                            </div>
                        <div class="col-lg-8">
                            <div class="property-photo-ltr pointer" id="top-photo" @click="openDetailModal"
                                :style="{backgroundImage:'url('+property.property_photos[0]+')'}">
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="property-photo-rtl">
                                <div class="top-photo-layer pointer" @click="openDetailModal" v-if="property.property_photos[1]"
                                :style="{backgroundImage:'url('+property.property_photos[1]+')'}"></div>

                                <div class="bottom-photo-layer pointer" @click="openDetailModal" v-if="property.property_photos[2]"
                                :style="{backgroundImage:'url('+property.property_photos[2]+')'}"></div>

                                <span class="property-photo-counter">
                                    <img src="@/assets/img/icons/image-icon.png" alt="" /> {{property.property_photos.length}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <property-detail-loader v-if="apiloading"/>
        <section class="property-single">
            <div class="container">
                <div class="property-single-wrapper">
                    <div class="row">
                        <div class="col-lg-12"></div>
                        <div class="col-lg-8">
                            <div class="property-single-LTR">
                                <div class="property-info-section">
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <div class="location">
                                                <h2>{{property.property_name}}</h2>
                                                <p>
                                                    <img src="@/assets/img/icons/location.png"
                                                        alt='Lekki penisula II, Lekki Lagos State, Nigeria'>
                                                    {{property.city.name}} {{property.state.name}}, Nigeria
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="property-price">
                                                <span>From</span>
                                                <h2>{{property.property_price | price}}.<small>00</small></h2>
                                                <a @click.prevent="selectProperty" class="get-pre-qualified-cta-btn pointer">Get prequalified</a>
                                            </div>
                                        </div>
                                        <div class="col-lg-8">
                                                <div class="amenities">

                                                <detail-amenity-card img="bed.png" alt="bed" cls="bed" :title="property.property_bedrooms+' beds'"/>
                                                <detail-amenity-card img="bath.png" alt="bath" cls="bath" :title="property.property_bathrooms+' baths'"/>
                                                <detail-amenity-card img="size.png" alt="sqft" cls="dimension" :title="property.property_size+' sqft'"/>
                                                <detail-amenity-card img="cars.svg" alt="sqft"  cls="dimension" :title="property.property_garages+' cars'"/>
                                                </div>
                                                <a href="#" @click.prevent="selectProperty"  class="screen414 get-pre-qualified-cta-btn">Get
                                                    prequalified</a>
                                        </div>
                                         <div class="col-lg-8">
                                                <div class="property-status-title-permits">
                                                    <div class="property-status">
                                                        <p>Property Status</p>
                                                        <h3>{{property.property_status.name}}</h3>
                                                    </div>
                                                    <div class="property-title">
                                                        <p>Property Title</p>
                                                        <h3>Not Available</h3>
                                                    </div>
                                                    <div class="property-permit">
                                                        <p>Building Permits</p>
                                                        <h3>No</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        <div class="col-lg-12">
                                            <div class="description">
                                                <h2>Description</h2>
                                                <p>{{property.property_description}}</p>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="amenities-list d-flex flex-lg-column">
                                                <div class="amenities-wrapper">
                                                    <h2>Amenities</h2>
                                                </div>

                                                <div class="amenities-content">
                                                    <ul class="list-unstyled d-flex flex-wrap">
                                                        <li v-for="item in property.property_amenities" :key="item.id">
                                                            <img class="amenities-icon"
                                                                src="@/assets/img/icons/verified.png"
                                                                alt="Fitted Kitchen">
                                                                {{item.name}}
                                                        </li>
                                                        
                                                    </ul>

                                                </div>
                                               

                                            </div>
                                        </div>

                                        <!-- <div class="col-lg-12">
                                            <div class="property-type">
                                                <h2>Property Types</h2>

                                                <table class='table align-items-center table-flush'>
                                                    <tbody class="property-type-list">
                                                        <tr>
                                                            <td>Terrance</td>
                                                            <td>$27,000,000.00</td>
                                                            <td><span class="badge available">Available</span>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Semi-detached</td>
                                                            <td>$37,000,000.00</td>
                                                            <td><span class="badge sold">Sold
                                                                    Out</span>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Detached</td>
                                                            <td>$48,000,000.00</td>
                                                            <td>
                                                                <span class="badge available">Available</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> -->

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <tour-request-form :property="property" class="mt-2"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <proeprty-detail-modal :property="property"/>
      
    </div>
</template>
<script>
import EventBus from '@/services/event'
import PropertyDetailLoader from '../components/PropertyDetailLoader.vue';
import { required, email, minLength, sameAs,requiredIf,numeric } from "vuelidate/lib/validators";
import {AppMixin} from '@/mixins/AppMixin';
import toast from '@/services/ToastService.js'
import DetailAmenityCard from '../components/DetailAmenityCard.vue';
import TourRequestForm from '../components/TourRequestForm.vue';
import ProeprtyDetailModal from '../components/ProeprtyDetailModal.vue';
export default {
    name:"PropertyDetail",
    mixins:[AppMixin],
    components:{
        PropertyDetailLoader,
        DetailAmenityCard,
        TourRequestForm,
        ProeprtyDetailModal
    },
    data(){
        return{
            slug:this.$route.params.slug,
   
        }
    },
 
    computed:{
        property(){
            let p = this.$store.state.properties_module.property;
            return p;
        },
        selected_id:{
            get(){
                return this.property.id;
            },
            set(v){
                this.form.property_id = v;
            }
        }
    },
    methods:{
        // getPreQua
        fetchProperty(slug){
            this.$store.dispatch("propertyDetailAction",slug)
        },
        selectProperty(){
            this.$store.dispatch("selectPropertyAction",this.property);
            this.$store.commit("SAVE_SELECTED_PROPERTY",this.property)
            this.$store.dispatch("formStepAction",1);
        },
        goback(){
            this.$router.go(-1)
        },
        openDetailModal(){
            EventBus.$emit('detail-modal',true);
        }
      
    },
    created(){
        this.fetchProperty(this.slug)
        this.$store.dispatch("formStepAction",1);
        // this.book_form.property_id = this.property.id
        // this.enquiry_form.property_id = this.property.id
        
    }
}
</script>
<style scoped>

</style>
