<template>
    <div>
    <section class='affordability-form'>
        <div class='container'>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class='affordability-form-top-content'>
                        <h2>You are almost there</h2>
                        <p>
                            These steps are necessary to complete your mortgage
                            application
                        </p>
                    </div>

                    <div class='affordability-form-wrapper'>
                        <div class='progress-bar'>
                            <div class='step'>
                                <div class='check completed'>{{step>1?'✓':''}} </div>
                                <div class='bullet' :class="[step==1?'active':step>1?'completed':'']">
                                    <span> {{step>1?'✓':'1'}}</span>
                                </div>
                                <p :class="[step==1?'active':step>1?'completed':'pending']">Affordability Test</p>
                            </div>

                            <div class='step'>
                                <div class='check completed'>{{step>2?'✓':''}} </div>
                                <div class='bullet ' :class="[step==2?'active':step>2?'completed':'']">
                                    <span> {{step>2?'✓':'2'}}</span>
                                </div>
                                <p :class="[step==2?'active':step>2?'completed':'pending']">Down Payment</p>
                            </div>
                            <div class='step'>
                                
                                <div class='check active'>3</div>
                                <div class='bullet ' :class="[step==3?'active':step>3?'completed':'']">
                                    <span>3</span>
                                </div>
                                <p :class="[step==3?'active':step>3?'completed':'pending']">Complete Profile</p>
                            </div>
                        </div>
                        <div :class="formOutline">
                                <div v-if="step==1">
                                    <affordability-form inputBg="transparent" :formType="false">

                                    </affordability-form>
                                </div>
                        </div>
                                
                                <div class="step2" v-if="step==2">
                                    <eligibility-form inputBg="#EFF0F6"/>
                                </div>
                                <div class="step3" v-if="step==3">
                                    <profile-form inputBg="transparent"/>
                                </div>
                      
                        <div class='form-group row'>
                            <div class='offset-lg-3 col-lg-6'>
                                <button type="button" @click.prevent="stepForward()" class="affordability-form-btn">
                                     <span v-if="!apiloading">Continue</span>
                                    <span v-if="apiloading">loading...</span>
                                </button>
                            </div>
                        </div>
                        <!-- </div> -->
                        
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
import AffordabilityForm from './AffordabilityForm.vue';
import EligibilityForm from './EligibilityForm.vue';
import ProfileForm from './ProfileForm.vue';
import {FormMixin} from '@/mixins/form'
import {CalculatorMixin} from '@/mixins/CalculatorMixin'
import {AppMixin} from '@/mixins/AppMixin'
import EventBus from '@/services/event'
export default {
    name:"StepForm",
    mixins:[FormMixin,CalculatorMixin,AppMixin],
    components:{AffordabilityForm, EligibilityForm, ProfileForm},
    data(){
        return{
        // step:1,
        // total_step:3,
        // stepStatus:{hasAffordability:false,hasEligibility:false}
        }
    },
    computed:{
        step:{
            get(){
                let r = this.$store.state.calculator_module.formStep;
            return r;
            },
            set(v){
                this.$store.commit("GO_TO_STEP",v);
            }
            
        },
        total_step(){
            let r = this.$store.state.calculator_module.totalStep;
            return r;
        },
        formOutline(){
            switch(this.step){
                case 1:
                    return 'col-md-8 offset-md-2';
                    
                case 2:
                    return 'col-md-10 offset-md-1';
                case 3:
                    return 'col-md-10 offset-md-1';
                default:
                    return 'col-md-8 offset-md-2';
            }
        },
     
        stepStatus(){
             let status = this.$store.state.calculator_module.processStatus;
             return status;
        },
    },
    methods:{
        validateStep(step){
            switch(step){
                case 1:
                   this.$store.dispatch("formStepAction",step);
                case 2:
                    if(!this.stepStatus.hasAffordability){
                        this.$store.dispatch("formStepAction",1);
                    }
                    break;
                case 3:
                     if(!this.stepStatus.hasEligibility && this.stepStatus.hasAffordability){
                        this.$store.dispatch("formStepAction",2);
                    }
                    break;

            }
        },
        stepForward(){
               switch(this.step){
                case 1:
                    this.submitAffordability();
                    break;
                case 2:
                    this.submitEligibility();
                    break;
                case 3:
                    this.submitProfile();
                    break;
        }
        },
        stepBackward(){},
        goStep(step){
        
        }
    },
    created(){
        
        // EventBus.$on('next-step',(step)=>{
        //    this.step = step;
        //    console.log(this.step)
        // })
        EventBus.$on('process-status',(status)=>{
            this.stepStatus = status;
             console.log(this.stepStatus);
        })
      
    },
     destroyed () {
         this.processStepFunction(false,false)
        this.step =1;
        this.$store.commit("CLEAR_FORM")
        },
}
</script>
<style  scoped>

</style>