<template>
    <div >
        <form action="#">
            <div class='form-wrapper down-payment'>
                                <div class='form-group row'>
                                    <div class='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                        <label class='form-control-label'>
                                            Property Value
                                            <span class='text-danger'>*</span>
                                        </label>
                                        <div class='input-group input-group-merge'>
                                            <div class='input-group-prepend'>
                                                <span class='input-group-text'>₦</span>
                                            </div>
                                            <input type='text'  v-model="property_value"  class='form-control ' placeholder=''
                                                readOnly />
                                        </div>
                                    </div>

                                    <div class='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                        <label class='form-control-label'>
                                            Loan Amount <span class='text-danger'>*</span>
                                        </label>
                                        <div class='input-group input-group-merge'>
                                            <div class='input-group-prepend'>
                                                <span class='input-group-text'>₦</span>
                                            </div>
                                            <input type='text' name='title' class='form-control ' placeholder=''  v-model="loan_amount" 
                                                readOnly />
                                        </div>
                                    </div>
                                </div>

                                <div class='form-group row'>
                                    <div class='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                        <label class='form-control-label'>Equity Contribution</label>
                                        <range-slider class="slider" :min="min_range" :max="max_range" step="1" v-model="down_rate">
                                            <template slot="knob">
                                              <div class="knobby">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20">
                                                  <path
                                                    fill="#CDD8C3"
                                                    fill-rule="evenodd"
                                                    d="M9.864 20c5.243-5.195 7.864-9.24 7.864-12.136A7.863 7.863 0 009.864 0 7.863 7.863 0 002 7.864C2 10.759 4.621 14.804 9.864 20z"
                                                  ></path>
                                                  <text x="10" y="10" fill="currentColor" text-anchor="middle">{{ down_rate + '%' }}</text>
                                                </svg>
                                              </div>
                                            </template>
                                          </range-slider>
                                          <div class="range-value d-flex justify-content-between">
                                                  <span>{{min}} </span>
                                                  <span>{{max}} </span>
                                          </div>
                                        
                                      <!-- <div class="range-div">
                                      <input type="range" ref="range" v-model="down_rate" class="form-range" :min="min_range" :max="max_range" >
                                      <output class="bubble" ref="bubble">{{down_rate}} {{resultType}}</output>
                                      </div>
                                  
                                      <div class="range-value d-flex justify-content-between">
                                          <span>{{min}} </span>
                                          <span>{{max}} </span>
                                      </div> -->
                                  
                                    </div>

                                    <div class='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                        <label class='form-control-label'>
                                            Equity Contribution Amount
                                            <span class='text-danger'>*</span>
                                        </label>
                                        <div class='input-group input-group-merge'>
                                            <div class='input-group-prepend'>
                                                <span class='input-group-text'>₦</span>
                                            </div>
                                            <input type='text' name='title' class='form-control ' placeholder=''  v-model="down_payment" 
                                                readOnly />
                                        </div>
                                    </div>
                                </div>


                                 
          </div>
            <slot name="button"></slot>
        </form>
    </div>
</template>
<script>
import EventBus from '@/services/event'
import {FormMixin} from '@/mixins/form'/*  */
import {CalculatorMixin} from '@/mixins/CalculatorMixin';
import {AppMixin} from '@/mixins/AppMixin'/*  */
import RangeSlider from "vue-range-slider";
import "vue-range-slider/dist/vue-range-slider.css";
export default {
  components: { RangeSlider },
    name:"EligibityForm",
      mixins:[FormMixin,CalculatorMixin,AppMixin],
    props:{
        inputBg:{type:String,default:'#fff'}
    },
    data(){
        return{
            min_range:10,
            max_range:90,
            resultType:'%',
            down_rate:5,
            max_loan_amount:0,
            form:{
              down_rate:'',
              property_value:'',
              loan_amount:'',
              down_payment:'',

            }
         
        }
    },
    
    watch:{
    
    'down_rate':function(val){
        // this.setBubble();
        this.reCalculateDownPayment(val);
    }
    },
    computed:{
        selectedProperty:function(){
             let s = this. $store.state.properties_module.selectedProperty;
             return s.property_price;
        },
        store_form(){
          let t = this.$store.state.calculator_module.form;
          return t;
        },
        property_value: {
          get() {
              let v =  this.formatToCommaSeperated(this.form.property_value);
            return v;
          },
          set(val) {
              this.form.property_value = val;

          }
        },
        loan_amount: {
          get() {
              let v =this.formatToCommaSeperated(this.form.loan_amount);
            return v;
          },
          set(val) {
            this.form.loan_amount = val;
            // this.$store.commit('loan_amount', val);

          }
        },
        down_payment: {
          get() {
               let v =this.formatToCommaSeperated(this.form.down_payment);
            return v;
          },
          set(val) {
            //    let p = this.removeCommaFromNumber(val)
            this.form.down_payment = val;

          }
        }, 
    },
    methods:{
        submitEligibility(){
          this.form.down_rate = this.down_rate;
          this.$store.dispatch("saveEligibilityFormAction",this.form);
          // this.processStepFunction(true,true);
          EventBus.$emit('open-down-payment-modal',true);
          //  this.$store.dispatch("formStepAction",3);
        },
        calculateInitialDownPayment(){
          // let store_form = this. $store.state.calculator_module.form;
          let max_loan_amount = this.store_form.max_loan_amount;
          let property_value = this.store_form.property_value;
         
          this.form.property_value = property_value;
          this.max_loan_amount = max_loan_amount;
          //get the ten percentage and 90%;
          let ten_percentage  = (property_value*0.1).toFixed(0);
          let ninty_percentage = (property_value*0.9).toFixed(0);
          //if affordabile amount is less then 90% property
          if(max_loan_amount >= ninty_percentage){
              this.form.loan_amount = ninty_percentage
            this.form.down_payment = ten_percentage;
          }else{
            // let r = ((this.max_loan_amount/property_value)*100).toFixed(0);
             this.form.loan_amount = max_loan_amount;
            this.form.down_payment =property_value - max_loan_amount;
          }
             /*get the calculated down_payment rate and
                the min range and the current down_rate
              */
          let r = Math.trunc(((this.form.down_payment/property_value)*100));
            console.log("rrr:"+r)
         
          if(r > this.max_range){
              this.max_range = 100;
               
          }
          this.min_range = r;
          this.down_rate = r;
            
        },
        reCalculateDownPayment(value){
          let rate = value/100;
          let max_loan_amount = this.store_form.max_loan_amount;
           let property_value = this.store_form.property_value;
          let ten_percentage  = (this.form.property_value*0.1).toFixed(0);
          let ninty_percentage = (property_value*0.9).toFixed(0);
          let new_down_payment = (rate * property_value).toFixed(0);
           let new_loan_amount =0;
          if(property_value - new_down_payment >= max_loan_amount){
            new_loan_amount = max_loan_amount;
            
          }
          else if(max_loan_amount >= property_value){
            new_loan_amount = (property_value-new_down_payment).toFixed(0);
            // this.form.down_payment = ten_percentage
          }
          else{
             new_loan_amount = (property_value-new_down_payment).toFixed(0);
          }
          this.form.loan_amount = new_loan_amount;
          this.form.down_payment = new_down_payment;
                
          // let r = Math.trunc(((this.form.down_payment/property_value)*100));
          // console.log("new_rate: "+r)
            //  this.down_rate = r;
          //  if( new_loan_amount >= max_loan_amount){
          //     this.form.loan_amount = max_loan_amount;
          //     this.form.down_payment = property_value - max_loan_amount;
          //     let r = ((this.max_loan_amount/property_value)*100).toFixed(0);
          //     this.min_range = r;
          //     this.down_rate = r;
          // }else{
          //   this.form.loan_amount = new_loan_amount;
          //   this.form.down_payment = property_value - new_loan_amount;;
          //   let r = ((this.new_loan_amount/property_value)*100).toFixed(0);
          //     // this.min_range = r;
          //     // this.down_rate = r;
          // }
          console.log("d: "+new_down_payment);
          console.log("l: "+new_loan_amount);
        }
    },
    created(){
        let s = this. $store.state.properties_module.selectedProperty;
        this.$store.commit("property_value",s.property_price);
        // this.$store.commit("down_payment",a.max_loan_amount);
       EventBus.$on('submitEligibility',(t)=>{
          this.submitEligibility();
        });
        this.calculateInitialDownPayment();
        // this.setBubble();
    },
      destroyed () {
          this.form =  {
              down_rate:'',
              property_value:'',
              loan_amount:'',
              down_payment:'',

            }
        },
}
</script>
<style  scoped>
   @media (max-width:1000px){

       .slider {
        width: 100%;
        margin-top: 3rem;
      }
    .form-wrapper .form-control-label, .form-wrapper legend {
    /* font-size: 11px; */
    margin-top: 0px;
    text-align: left;
    margin-bottom: 0;
    }
    .form-wrapper .form-control-label {
    background: transparent;
    position:relative;
    left:2%;
    }
  .form-wrapper{
      /* padding: 1rem 1rem; */
  }
}
</style>