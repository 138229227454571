<template>
  <div >
    <section class='faqs-page'>
                <div class='container'>
                    <div class='faqs-page-wrapper'>
                        <div class='row'>
                            <div class='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                <div class='faqs-page-top-content'>
                                    <h2>Frequently Asked Questions</h2>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Volutpat non pharetra, phasellus ut cursus.
                                    </p>
                                </div>

                                <div class='panel-group' id='accordion' role='tablist' aria-multiselectable='true'>
                                    <div class='panel panel-default'>
                                        <div class='panel-heading' role='tab' id='headingOne'>
                                            <h4 class='panel-title'>
                                                <a class='collapsed' data-toggle='collapse' data-parent='#accordion'
                                                    href='#collapseOne' aria-expanded='true'
                                                    aria-controls='collapseOne'>
                                                    Question 1
                                                </a>
                                            </h4>
                                        </div>
                                        <div id='collapseOne' class='panel-collapse in collapse show' role='tabpanel'
                                            aria-labelledby='headingOne'>
                                            <div class='panel-body'>
                                                <p>
                                                    Here’s some example text that may answer an FAQ or
                                                    give the user some helpful advice.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class='panel panel-default'>
                                        <div class='panel-heading' role='tab' id='headingTwo'>
                                            <h4 class='panel-title'>
                                                <a class='collapsed' data-toggle='collapse' data-parent='#accordion'
                                                    href='#collapseTwo' aria-expanded='false'
                                                    aria-controls='collapseTwo'>
                                                    Question 2
                                                </a>
                                            </h4>
                                        </div>
                                        <div id='collapseTwo' class='panel-collapse collapse' role='tabpanel'
                                            aria-labelledby='headingTwo'>
                                            <div class='panel-body'>
                                                <p>
                                                    Here’s some example text that may answer an FAQ or
                                                    give the user some helpful advice.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class='panel panel-default'>
                                        <div class='panel-heading' role='tab' id='headingThree'>
                                            <h4 class='panel-title'>
                                                <a class='collapsed' data-toggle='collapse' data-parent='#accordion'
                                                    href='#collapseThree' aria-expanded='false'
                                                    aria-controls='collapseThree'>
                                                    Question 3
                                                </a>
                                            </h4>
                                        </div>
                                        <div id='collapseThree' class='panel-collapse collapse' role='tabpanel'
                                            aria-labelledby='headingThree'>
                                            <div class='panel-body'>
                                                <p>
                                                    Here’s some example text that may answer an FAQ or
                                                    give the user some helpful advice.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class='panel panel-default'>
                                        <div class='panel-heading' role='tab' id='headingFour'>
                                            <h4 class='panel-title'>
                                                <a class='collapsed' data-toggle='collapse' data-parent='#accordion'
                                                    href='#collapseFour' aria-expanded='false'
                                                    aria-controls='collapseFour'>
                                                    Question 4
                                                </a>
                                            </h4>
                                        </div>
                                        <div id='collapseFour' class='panel-collapse collapse' role='tabpanel'
                                            aria-labelledby='headingFour'>
                                            <div class='panel-body'>
                                                <p>
                                                    Here’s some example text that may answer an FAQ or
                                                    give the user some helpful advice.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class='panel panel-default'>
                                        <div class='panel-heading' role='tab' id='headingFive'>
                                            <h4 class='panel-title'>
                                                <a class='collapsed' data-toggle='collapse' data-parent='#accordion'
                                                    href='#collapseFive' aria-expanded='false'
                                                    aria-controls='collapseFive'>
                                                    Question 5
                                                </a>
                                            </h4>
                                        </div>
                                        <div id='collapseFive' class='panel-collapse collapse' role='tabpanel'
                                            aria-labelledby='headingFive'>
                                            <div class='panel-body'>
                                                <p>
                                                    Here’s some example text that may answer an FAQ or
                                                    give the user some helpful advice.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class='panel panel-default'>
                                        <div class='panel-heading' role='tab' id='headingSix'>
                                            <h4 class='panel-title'>
                                                <a class='collapsed' data-toggle='collapse' data-parent='#accordion'
                                                    href='#collapseSix' aria-expanded='false'
                                                    aria-controls='collapseSix'>
                                                    Question 6
                                                </a>
                                            </h4>
                                        </div>
                                        <div id='collapseSix' class='panel-collapse collapse' role='tabpanel'
                                            aria-labelledby='headingSix'>
                                            <div class='panel-body'>
                                                <p>
                                                    Here’s some example text that may answer an FAQ or
                                                    give the user some helpful advice.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    
  </div>
</template>
<script>
export default {
  name:"Faq"
}
</script>
<style lang="scss" scoped>
    
</style>
