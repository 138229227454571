<template>
    <div >
      <affordability-result />
       <section class="property-affordable-list">
                <div class="container-fluid">
                    <div class="property-affordable-list_wrapper row">
                        <div class="col-lg-12">
                            <div class="property-affordable-list-top-content">
                                <h2>Filtered result for you</h2>
                                <p>Based on your Affordability result, we have filtered down properties that are
                                    suitable and affordable to you.</p>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="property-cards-wrapper">
                                
                                   <div class="row" v-if="!apiloading" >
                     
                                        <div class="col-lg-3" v-for="i in properties" :key="i.id">
                                           <div class="property-list pr-0 v2">
                                                 <new-property-card :isChoose="true" :property="i"/>
                                           </div>
                                           
                                            
                                        </div>
                                    </div>
                                    <div class="row" v-if="apiloading" >
                                        <div class="col-lg-3" v-for="i in 10" :key="i">
                                            <property-card-loader/>
                                            
                                            </div>
                                    </div>
                            </div>
                        </div>
                          
                    </div>
                      <pagination  :pagination="pagination" title="Properties" mutator="AFFORDABLE_PROPERTIES" method="post" />
                </div>
      </section>
       <section class="banner-cta">
          <div class="container-fluid">
              <div class="banner-cta-wrapper">
                  <div class="row">
                      <div class="col-lg-12">
                          <div class="banner-cta-content">
                              <p>Still dont get what you are looking for, we can make provision based on your
                                  suggestions</p><a @click.prevent="showRequestModal()" class="waiting-list-cta pointer">Join the waiting list</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </div>
</template>
<script>

import {AppMixin} from '@/mixins/AppMixin'
import {PropertiesMixin} from '@/mixins/PropertiesMixin';

export default {
    name:"FilterProperties",
    mixins:[AppMixin,PropertiesMixin],
     data(){
    return{
      property_length:4,
      pageOfItems: []
    }
  },
  computed:{
     properties(){
        let all = this.$store.state.properties_module.affordable_properties;
        let property = all.data;
           this.fillPagination(all);
        // let price =this.$store.state.calculator_module.affordabilityResult;
        // return all.filters((p)=>{
        //   return p.property_price <= price;
        // })
        return property;
      }
  },
  methods:{
    onChangePage(pageOfItems) {
            // update page of items
            this.pageOfItems = pageOfItems;
        },
    
    fetchProperties(){
      let p = this.$store.state.calculator_module.form;
      let data = {price:p.max_loan_amount,location:p.location};
      this.$store.dispatch("affordablePropertiesAction",data);
    },
    openPropertyRequest(){
        
    }
  },
  created(){
    this.fetchProperties();
  }
}
</script>
<style scoped>
    @media (max-width:1000px){
/* .property-affordable-list_wrapper .property-affordable-list-top-content {
    padding:0;
} */
    }
</style>