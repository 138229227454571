<template>
    <div >
         <affordability-result/>
         <selected-property class="shift-up"/>
         <step-form/>
         <div class="text-center">
               <down-payment-modal/>
         </div>
       
    </div>
</template>
<script>
import DownPaymentModal from '../components/DownPaymentModal.vue'
import StepForm from '../components/StepForm'
export default {
  components: { StepForm,DownPaymentModal },
    name:"Affordability",
    computed:{
        // selectedProperty(){
        //     let s = this. $store.state.properties_module.selectedProperty;
        //     return s;
        // }
    },
   
    
}
</script>
<style scoped>
.shift-up{
    margin-top:-5rem;
}
</style>