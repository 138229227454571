<template>
    <div>
        <section class="property-photo">
            <div class="container">
                <div class='property-photo-wrapper'>
                    <div class="row no-gutters">
                        <div class="col-lg-8">
                            <div class="property-photo-ltr">
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="property-photo-rtl">
                                <div class="top-photo-layer" ></div>

                                <div class="bottom-photo-layer"></div>

                                <span class="property-photo-counter">
                                    <!-- <img src="@/assets/img/icons/image-icon.png" alt="" />  -->
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="property-single">
            <div class="container">
                <div class="property-single-wrapper">
                    <div class="row">
                        <div class="col-lg-12"></div>
                        <div class="col-lg-8">
                            <div class="property-single-LTR">
                                <div class="property-info-section">
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <div class="location">
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="property-price">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="description">
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="request-schedule-form">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name:"PropertyDetailLoader"
}
</script>
<style  scoped>

   .property-photo 
  .property-photo-ltr,
  .top-photo-layer,
  .bottom-photo-layer
 {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
    .property-single
  .property-price,
  .description,
  .request-schedule-form,
  .location h2,p{
         background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
  .property-price{
   height: 100px;
  }
  .property-single.location h2{
      height: 60px;
  }
  .property-single.location p{
      height: 40px;
  }
.property-single .description{
    margin-top:10px;
    height: 100px;
}
.request-schedule-form{
    height: 200px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
</style>