<template>
    <div>
     
         <section class="search-filter v2">
                <div class="container-fluid">
                    <div class="search-filter-wrapper w-100">
                        <form>
                            <div class="row no-gutters">
                                <div class="col-lg-10 search-filter-form">
                                    <div class="search-name-state-city">
                                        <div class="search-icon-holder">
                                            <img src="@/assets/img/icons/zoom.png">
                                        </div>
                                        <div class="search-holder-input">
                                            <input type="text" class="form-control"  v-model="form.location"
                                                placeholder="Name, State, City..." />
                                        </div>
                                    </div>

                                    <div class="search-property-type">
                                        <div class="search-icon-holder">
                                            <img src="@/assets/img/icons/union.png">
                                        </div>
                                        <div class="search-holder-input">
                                            <select class="form-control" v-model="form.property_type" aria-describedby="All Home Types">
                                                <option value="">All Home Types</option>
                                               <option :value="i.name" v-for="i in property_type_list" :key="i.id">{{i.name}}</option>

                                               
                                                
                                            </select>
                                        </div>
                                    </div>

                                    <div class="search-maximum-price">
                                        <div class="search-icon-holder">
                                            <img src="@/assets/img/icons/naira-icon.png">
                                        </div>
                                        <div class="search-holder-input">
                                            <input type="text" class="form-control" placeholder="Maximum price" v-model="price" />
                                        </div>
                                    </div>

                                    <div class="search-property-status">
                                        <div class="search-icon-holder">
                                            <img src="@/assets/img/icons/union.png">
                                        </div>
                                        <div class="search-holder-input">
                                           
                                              <select class="form-control" v-model="form.property_status" aria-describedby="Property Status">
                                                <option value="">Property Status</option>
                                               <option :value="i.id" v-for="i in property_status_list" :key="i.id">{{i.name}}</option>

                                               
                                                
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-2">
                                    <button type="submit" @click.prevent="search" class="search-filter-btn">Search</button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
        </section>
    </div>
</template>
<script>
import {FormMixin} from '@/mixins/form'
import {AppMixin} from '@/mixins/AppMixin'
export default {
    name:"SearchProperties",
    mixins:[FormMixin,AppMixin],
        data(){
        return{
            form:{
                location:'',
                bedrooms:'',
                bathrooms:'',
                price:'',
                property_type:'',
                property_status:''
            }
        }
    },
    computed:{
        price:{
             get() {
              let r = this.formatToCommaSeperated(this.form.price
              );
              return r;
            },
            set(val) {
              this.form.price=val;

            }
        }
    },
    methods:{
       
        search(){
             if(this.form.location != ''|| this.form.property_type!=''|| this.form.price != ''){
                 this.$store.dispatch("searchPropertiesAction",this.form).then((r)=>{

                 });
             }
            
        }
    }
}
</script>
<style scoped>
.search-name-state-city,.search-property-type,.search-maximum-price,.search-property-status{
    width:25%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
   
    background:transparent;
}
.search-name-state-city,.search-property-type,.search-maximum-price{
     border-right:1px solid #A0A3BD !important;
}

.search-filter-form{
     border:1px solid #A0A3BD ;
     border-radius: 12px;
     background-color: #fff;
}
.search-property-status .search-icon-holder {
    position: relative;
    display: flex;
    top: 0;
    right: 0;
}
.search-name-state-city .search-icon-holder{
     position: relative;
    display: flex;
    top: 0;
    right: 0;
    left:10px;
}
.search-property-type .search-icon-holder {
     position: relative;
    display: flex;
    top: 0;
    right: 0;
    left:10px;
}
.search-maximum-price .search-icon-holder{
     position: relative;
    display: flex;
    top: 0;
    right: 0;
    left:10px;
}
.search-icon-holder img {
    width: 20px;
    opacity: 1;
    height: 20px;
}
.search-property-status .search-icon-holder {
    left:0.8rem;
}
.search-filter-form input{
    border:none !important;
}
.search-holder-input select{
    border:none !important;
}
.form-control{
    background-color: transparent;
}
.show-right-border{
    border-right: 1px soild #A0A3BD;
}
@media (max-width:1000px){
    .search-filter-form{
        display: flex;
        flex-direction: column;
    }
    .search-name-state-city,.search-property-type,.search-maximum-price,.search-property-status{
    width:100%;
    }
    .search-name-state-city,.search-property-type,.search-maximum-price{
     border-bottom:1px solid #A0A3BD;
     border-right:none !important;
    }


    .search-filter-btn{
        width:100%;
        margin:0.8rem auto;

    }
    .search-filter .search-filter-wrapper {
        padding:1rem;
        /* margin-top:3rem; */
    }
}
</style>