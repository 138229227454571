<template>
    <div>
        <section class='mortgage-hero-banner'  :style="{backgroundImage:'url('+require('@/assets/img/mortgage-hero-banner.png')+')'}">
                <div class='container'>
                    <div class='mortgage-hero-banner-wrapper'
                   >
                        <div class='row'>
                            <div class='col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                                <div class='mortgage-hero-content'>
                                    <h2>Looking for Morgage?</h2>
                                    <p>
                                        Let Housing Solution Fund be your guide! Take the First step
                                    </p>
                                    <a href='#' data-toggle="modal"
                                            data-target="#AffordabilityTestModal" class='mortgage-cta-btn pointer' >
                                        Get Prequalified
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
         
            <section class='partnership-section'>
                <div class='container'>
                    <div class='partnership-section-wrapper'>
                        <div class='row'>
                            <div class='col-lg-4'>
                                <h2>Our Partnership</h2>
                                <p>
                                    We partner with the best Mortgage brokers in the business,
                                    with mouth watering Rates!
                                </p>
                            </div>
                            <div class='col-lg-8'>
                                <div class='partnership-section-list'>
                                    <div class="partner-slider">
                                        <figure class="partner-img">
                                            <img class="img-fluid" src='@/assets/img/first-trust.png'
                                                alt='First Trust'/>
                                        </figure>

                                        <figure class="partner-img">
                                            <img class="img-fluid" src='@/assets/img/imperial.png' alt='Imperial Homes'/>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class='mortgage-how-it-work'>
                <div class='container'>
                    <div class='how-it-work-wrapper'>
                        <h2>How it works!</h2>

                        <div class='how-it-work-content row'>
                            <div class='col-lg-4'>
                                <figure>
                                    <img src="@/assets/img/icons/search.png" alt='We find your perfect mortgage' />
                                </figure>
                                <h5>We find your perfect mortgage</h5>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. A
                                    bibendum eget tellus non sed pretium magna enim. Dignissim et
                                    est et in sagittis tortor nec. Sagittis nullam id imperdiet
                                    pulvinar mauris.
                                </p>
                            </div>
                            <div class='col-lg-4'>
                                <figure>
                                    <img src='@/assets/img/icons/approve.png' alt='Get your  mortgage
                            Approved' />
                                </figure>
                                <h5>Get your mortgage Approved</h5>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. A
                                    bibendum eget tellus non sed pretium magna enim. Dignissim et
                                    est et in sagittis tortor nec. Sagittis nullam id imperdiet
                                    pulvinar mauris.
                                </p>
                            </div>
                            <div class='col-lg-4'>
                                <figure>
                                    <img src='@/assets/img/icons/keys.png' alt='Get your Keys' />
                                </figure>
                                <h5>Get your dream home Keys</h5>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. A
                                    bibendum eget tellus non sed pretium magna enim. Dignissim et
                                    est et in sagittis tortor nec. Sagittis nullam id imperdiet
                                    pulvinar mauris.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class='mortgage-testimonials v2'>
                <div class='container'>

                    <div class="mortgage-testimonials-wrapper version-one">
                        <article>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div id="client-testimonial-carousel" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner" role="listbox">
                                            <div class="carousel-item active">
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <figure>
                                                            <img class="img-fluid"
                                                                src="@/assets/img/sample-client-img.png"
                                                                alt="housing fund">
                                                        </figure>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="what-they-have-to-say">
                                                            <div class="top-img">
                                                                <img src="@/assets/img/icons/approve.png" alt="" />
                                                            </div>
                                                            <h2>Zero purchase commission</h2>
                                                            <p>
                                                                Searching for a home? get help finding and financing
                                                                your dream home today all commision free on HSF.
                                                            </p>

                                                            <a class="mortgate-test-cta-btn text-white pointer" @click="showAffordabilityModal">
                                                                Take the Affordability Test
                                                            </a>

                                                            <div class="bottom-img">
                                                                <img src="@/assets/img/icons/keys.png" alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="carousel-item">
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <figure>
                                                            <img class="img-fluid" src="@/assets/img/slider-image.png"
                                                                alt="Housing fund">
                                                        </figure>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="what-they-have-to-say">
                                                            <div class="top-img">
                                                                <img src="@/assets/img/icons/approve.png" alt="" />
                                                            </div>
                                                            <h2>Hassle free Mortgage</h2>
                                                            <p>
                                                                Getting home loans just got easy!
                                                                Get prequalified, apply for a mortgage,
                                                                and track your application online, on Housing
                                                                Solution Fund.
                                                            </p>

                                                            <a class="mortgate-test-cta-btn text-white pointer" @click="showAffordabilityModal">
                                                                Take the Affordability Test
                                                            </a>

                                                            <div class="bottom-img">
                                                                <img src="@/assets/img/icons/keys.png" alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="carousel-item">
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <figure>
                                                            <img class="img-fluid"
                                                                src="@/assets/img/sample-client-img.png"
                                                                alt="housing fund">
                                                        </figure>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="what-they-have-to-say">
                                                            <div class="top-img">
                                                                <img src="@/assets/img/icons/approve.png" alt="" />
                                                            </div>
                                                            <h2>Are you a Real Estate Developer?</h2>
                                                            <p>
                                                                Quality lead is Guaranteed!
                                                                Showcase your properties and gain insights
                                                                into the needs of prospective homebuyers.
                                                            </p>

                                                            <a class="mortgate-test-cta-btn text-white pointer" @click="showAffordabilityModal">
                                                                Take the Affordability Test
                                                            </a>

                                                            <div class="bottom-img">
                                                                <img src="assets/img/icons/keys.png" alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <ol class="carousel-indicators">
                                            <li data-target="#client-testimonial-carousel" data-slide-to="0"
                                                class="active"></li>
                                            <li data-target="#client-testimonial-carousel" data-slide-to="1"></li>
                                            <li data-target="#client-testimonial-carousel" data-slide-to="2"></li>
                                        </ol>
                                    </div>
                                </div>




                            </div>
                        </article>
                    </div>


                </div>
            </section>
             
    </div>
</template>
<script>
import {AppMixin} from '@/mixins/AppMixin';
import HowItWorkCarousel from '../components/HowItWorkCarousel.vue';
export default {
    name:"Mortgage",
    mixins:[AppMixin],
    components:{
        HowItWorkCarousel
    }
    
}
</script>
<style  scoped>
    .flex-parent{
        display: flex;
        justify-content: flex-start;
    }
    @media (max-width:1000px){
        .flex-parent{
            display: flex;flex-direction: column;
         }
    }
</style>